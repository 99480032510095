import * as React from "react"
import Layout from "../components/layout"

import { ParallaxProvider } from 'react-scroll-parallax';

const SalaPosePage = ({location}) => {
  

  return (
    <Layout location={location} title=''>
      
      <ParallaxProvider>
      
      <section className="bg-white pb-40 relative z-20">
        <h1 className="text-black text-center pb-20 relative top-10 tracking-wider text-4xl">Sala Pose</h1>
        <h3 className="text-sm text-center p-0">Noleggio Sala pose Napoli</h3>
        <div className="container max-w-screen-xl grid md:grid-cols-2 gap-16 border-2 my-5">
        <div className="p-10">
            <p>
            Studio 125 dispone , nel centro di Napoli, di una Sala Pose per il noleggio, fornita di diversi  Flash con commander Wireless, Pannelli Led Rgb Cri 90+, Pannelli Led 3000k/6500K Cri 95+, Macchina del fumo, portafondali e fondali rimovibili.

La Sala è climatizzata e riscaldata autonomamente, dispone di connessione in Fibra Ottica da 2.5 Gb/s, Macchina del Caffè, Postazione Make Up, Stampante Fine Art A3+.

E’ possibile noleggiare attrezzatura Leica o di altri brand su richiesta.
            </p>
        </div>
          <div>
Noleggio Sala Pose Giornata Intera
€300
Aria Condizionata - WiFi - Postazione Make Up - Camerino -

Noleggio Sala Pose - Mezza Giornata (5h)
€170
Aria Condizionata - WiFi - Postazione Make Up - Camerino -

Noleggio Sala Pose ad Ora
€ 45
Aria Condizionata - WiFi - Postazione Make Up - Camerino -
          </div>
        </div>
      </section>

      </ParallaxProvider>
      
    </Layout>
  )
}

export default SalaPosePage

